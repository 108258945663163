import { device } from 'src/styles/breakpoints'
import { grayscale, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Wrapper = styled.div`

  h1, h2, h3, h4, h5 {
    font-family: 'Citrina', Helvetica, sans-serif;
  }

  h2 {
    margin-bottom: 20px;
  }

  .bread-crumb {
    span, span a {
      color: ${grayscale[400]} !important;
    }
  }

  .phone-carousel {

    @media ${device.desktopLG} {
      .react-multi-carousel-track {
        padding-bottom: 0;
      }
    }
  }
`
export const Number = styled.span`
  height: 44px;
  width: 44px;
`
export const FAQSection = styled.section`
  .search-input {
    background: ${white};
  }
`
