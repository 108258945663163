import { breakpoints } from 'src/styles/breakpoints';
import { white } from 'src/styles/colors';
import styled, { css, keyframes } from 'styled-components';

type ContainerProps = {
  isModal?: boolean;
}

const animatedModal = keyframes`
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
`
export const Container = styled.div<ContainerProps>`
  background-color: ${white};
  h3 {
    font-family: "Sora", Helvetica, sans-serif;
  }
  img {
    max-width: 200px;
  }

  ${(props: ContainerProps) => props.isModal && (
    css`
      min-height: 100vh;
      padding: 30px 24px;;
      position: fixed;
      width: 100%;
      top: 0;
      bottom: 0;
      right: 0;
      transition: 0.5s ease-in-out;
      animation: ${animatedModal} 0.5s ease-in-out forwards;
      overflow-y: auto;

      @media (min-width: ${breakpoints.md}) {
        max-width: 596px;
        padding: 20px 40px;
      }
    `
  )}

  .text-underline {
    text-decoration: underline !important;
  }


  .bdr {
    border: solid 1px #FF7A00;
    text-transform: none;
    :hover{
       color: #FF7A00;
    }
  }

  .form--default {   
    label {
      color: #6A6C72;
      margin-bottom: 4px;
    }

    .form-label-check { 
      padding-left: 5px;  
      padding-right: 45px;
      &::before {
        top: 28px;
        left: 95%;
      }   
      @media (min-width: ${breakpoints.md}) {
          &::before {
          top: 5px;
          left: 465px;
        }   
      }
    }
    .form-input-check {
      &:checked ~ label {
      &::after {
        top: 31px;   
        left: 97%;  
        }
      }
      @media (min-width: ${breakpoints.md}) {
          &:checked ~ label {
        &::after {
          top: 8px;   
          left: 472px;  
          }
        }
      }
    }
  }

  &.sent {
    @media (min-width: ${breakpoints.md}) {
      padding-left: 70px;
      padding-right: 70px;
    }   
  }

  &.qrcode {
    @media (min-width: ${breakpoints.md}) {
      padding-left: 70px;
      padding-right: 70px;
    }   
  }

  &.under-age {
    img {
      max-width: 204px;
    }
  }

  &.filled {
    @media (min-width: ${breakpoints.md}) {
      padding-left: 80px;
      padding-right: 80px;
    }
  }
`

export const CloseButton = styled.button`
  position: absolute;
  right: 25px;
  top: 0;
  z-index: 10;
  outline: none !important;
`
export const QRCodeDiv = styled.div`
  padding: 36px 0;
  div {
    max-width: 200px;
    max-height: 200px;
  }
`
export const SucessoDiv = styled.div`
  padding: 36px 0;
  div {
    max-width: 64px;
    max-height: 64px;
  }
`